import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(
        VContainer,
        [
          _c(
            VForm,
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { attrs: { md: "12", lg: "12" } },
                    [
                      _c(
                        VCardTitle,
                        {
                          staticClass:
                            "pa-3 text-center grey--text font-weight-black",
                          style: _vm.isExtraSmall
                            ? "display: flex; justify-content: center;"
                            : ""
                        },
                        [
                          !_vm.isExtraSmall
                            ? _c(
                                VIcon,
                                { staticClass: "mx-3", attrs: { large: "" } },
                                [_vm._v(" mdi-chevron-down ")]
                              )
                            : _vm._e(),
                          _vm._v(" Criar novo usuário ")
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        { class: !_vm.isExtraSmall ? "alinhar-centro" : "" },
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "12", sm: "8", md: "8" } },
                            [
                              _c(
                                VCardText,
                                { class: !_vm.isExtraSmall ? "mx-3" : "py-0" },
                                [
                                  _vm._v(
                                    " Informe os detalhes do usuário que será criado, suas permisões e selecione o tipo de usuário "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            {
                              staticClass: "d-flex pr-6",
                              class: _vm.isExtraSmall
                                ? "justify-center pt-0"
                                : "justify-end",
                              attrs: { cols: "12", sm: "4", md: "4" }
                            },
                            [
                              _c(
                                VTooltip,
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            VBtn,
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    color: "primary white--text"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.modalPermissoes = true
                                                    }
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [_vm._v(" Ver Permissões ")]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " Clique para visualizar com mais detalhes as permissões de cada Tipo de Usuário. "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c(
                                VCol,
                                {
                                  class: _vm.isExtraSmall ? "pb-0" : "",
                                  attrs: { cols: "12", sm: "12", md: "12" }
                                },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      solo: "",
                                      "prepend-icon": !_vm.isExtraSmall
                                        ? "mdi-text-account"
                                        : "",
                                      name: "Nome",
                                      label: "Nome",
                                      type: "text",
                                      counter: "",
                                      maxlength: "45",
                                      hint:
                                        "Tamanho máximo 45 (não permite números e acentos)",
                                      rules: _vm.nameRulesConfirmation,
                                      required: "",
                                      "data-cy": "usuarioNome"
                                    },
                                    model: {
                                      value: _vm.usuario.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.usuario, "name", $$v)
                                      },
                                      expression: "usuario.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                { staticClass: "px-3 pt-3 pb-0" },
                                [
                                  _c(
                                    VCol,
                                    {
                                      class: _vm.isExtraSmall ? "py-0" : "",
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "12"
                                      }
                                    },
                                    [
                                      _vm.isSuperAdminRecuperi
                                        ? _c(VTextField, {
                                            attrs: {
                                              solo: "",
                                              "prepend-icon": !_vm.isExtraSmall
                                                ? "mdi-account-circle"
                                                : "",
                                              name: "login",
                                              label: "Login",
                                              type: "text",
                                              rules:
                                                _vm.loginRulesAdminConfirmation,
                                              required: "",
                                              autocomplete: "off",
                                              "data-cy": "usuarioLogin"
                                            },
                                            on: {
                                              blur: function($event) {
                                                return _vm.verificarPreenchimentoLoginAdmin()
                                              }
                                            },
                                            model: {
                                              value: _vm.usuario.ds_login,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.usuario,
                                                  "ds_login",
                                                  $$v
                                                )
                                              },
                                              expression: "usuario.ds_login"
                                            }
                                          })
                                        : _c(VTextField, {
                                            attrs: {
                                              solo: "",
                                              "prepend-icon": !_vm.isExtraSmall
                                                ? "mdi-account-circle"
                                                : "",
                                              name: "login",
                                              label: "Login",
                                              type: "text",
                                              rules: _vm.loginRulesConfirmation,
                                              required: "",
                                              autocomplete: "off",
                                              "data-cy": "usuarioLogin"
                                            },
                                            on: {
                                              blur: function($event) {
                                                return _vm.verificarPreenchimentoLogin()
                                              }
                                            },
                                            model: {
                                              value: _vm.usuario.ds_login,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.usuario,
                                                  "ds_login",
                                                  $$v
                                                )
                                              },
                                              expression: "usuario.ds_login"
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      class: _vm.isExtraSmall ? "py-0" : "",
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "12"
                                      }
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          solo: "",
                                          "prepend-icon": !_vm.isExtraSmall
                                            ? "email"
                                            : "",
                                          name: "email",
                                          label: "Email",
                                          type: "text",
                                          rules: _vm.emailRulesConfirmation,
                                          required: "",
                                          autocomplete: "off",
                                          "data-cy": "usuarioEmail"
                                        },
                                        model: {
                                          value: _vm.usuario.ds_email,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.usuario,
                                              "ds_email",
                                              $$v
                                            )
                                          },
                                          expression: "usuario.ds_email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      class: _vm.isExtraSmall ? "py-0" : "",
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "6"
                                      }
                                    },
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          solo: "",
                                          color: "success",
                                          items: _vm.tpUsuario,
                                          label: "Tipo de usuário",
                                          rules: _vm.userTypeRulesConfirmation,
                                          "prepend-icon": !_vm.isExtraSmall
                                            ? "mdi-chevron-right"
                                            : "",
                                          required: "",
                                          "data-cy": "usuarioTipo"
                                        },
                                        model: {
                                          value: _vm.usuario.tp_usuario,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.usuario,
                                              "tp_usuario",
                                              $$v
                                            )
                                          },
                                          expression: "usuario.tp_usuario"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      class: _vm.isExtraSmall ? "py-0" : "",
                                      attrs: {
                                        cols: "12",
                                        sm: "12",
                                        md: "12",
                                        lg: "6"
                                      }
                                    },
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          solo: "",
                                          disabled:
                                            _vm.loadingAgrupadores ||
                                            _vm.usuarioAgrupador != null,
                                          loading: _vm.loadingAgrupadores,
                                          items: _vm.agrupadores,
                                          label: "Agrupador",
                                          "prepend-icon": !_vm.isExtraSmall
                                            ? "mdi-chevron-right"
                                            : "",
                                          "item-text": "nm_agrupador",
                                          "item-value": "ds_agrupador",
                                          "data-cy": "usuarioAgrupador"
                                        },
                                        model: {
                                          value: _vm.usuario.ds_agrupador,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.usuario,
                                              "ds_agrupador",
                                              $$v
                                            )
                                          },
                                          expression: "usuario.ds_agrupador"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", sm: "4", md: "4" } },
                                    [
                                      _c(
                                        VTooltip,
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    VRow,
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center"
                                                    },
                                                    [
                                                      _c(VSwitch, {
                                                        attrs: {
                                                          outlined: "",
                                                          color: "success",
                                                          label: "Está ativo?",
                                                          required: "",
                                                          "data-cy":
                                                            "usuarioAtivo"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.usuario
                                                              .st_ativo,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.usuario,
                                                              "st_ativo",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                                                        usuario.st_ativo\n                                                    "
                                                        }
                                                      }),
                                                      !_vm.isExtraSmall
                                                        ? _c(
                                                            VBtn,
                                                            {
                                                              staticClass:
                                                                "ma-2",
                                                              attrs: {
                                                                elevation: "0",
                                                                small: "",
                                                                fab: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                VIcon,
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "grey lighten-2"
                                                                      }
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " help "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Selecione essa opção para definir se o usuário está ativo. Caso o usuário estiver inativo, não irá conseguir acessar o sistema. "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", sm: "4", md: "4" } },
                                    [
                                      _c(
                                        VTooltip,
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    VRow,
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center"
                                                    },
                                                    [
                                                      _c(VSwitch, {
                                                        attrs: {
                                                          outlined: "",
                                                          color: "success",
                                                          value: "A",
                                                          label:
                                                            "Usuario de API",
                                                          required: "",
                                                          "data-cy":
                                                            "usuarioAPI"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.usuario
                                                              .tp_perfil,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.usuario,
                                                              "tp_perfil",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                                                        usuario.tp_perfil\n                                                    "
                                                        }
                                                      }),
                                                      !_vm.isExtraSmall
                                                        ? _c(
                                                            VBtn,
                                                            {
                                                              staticClass:
                                                                "ma-2",
                                                              attrs: {
                                                                elevation: "0",
                                                                small: "",
                                                                fab: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                VIcon,
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "grey lighten-2"
                                                                      }
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " help "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " Usuários de API são usados exclusivamente para integrações. Selecione essa opção para definir se o usuário é de API. "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", sm: "4", md: "4" } },
                                    [
                                      _c(
                                        VTooltip,
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    VRow,
                                                    {
                                                      staticClass:
                                                        "d-flex justify-center"
                                                    },
                                                    [
                                                      _c(VSwitch, {
                                                        attrs: {
                                                          outlined: "",
                                                          color: "success",
                                                          disabled: !_vm.isUsuarioLite,
                                                          label:
                                                            "Apenas Leitura",
                                                          required: "",
                                                          "data-cy":
                                                            "usuarioReadonly"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.usuario
                                                              .is_lite_readonly,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.usuario,
                                                              "is_lite_readonly",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                                                        usuario.is_lite_readonly\n                                                    "
                                                        }
                                                      }),
                                                      !_vm.isExtraSmall
                                                        ? _c(
                                                            VBtn,
                                                            {
                                                              staticClass:
                                                                "ma-2",
                                                              attrs: {
                                                                elevation: "0",
                                                                small: "",
                                                                fab: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                VIcon,
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "grey lighten-2"
                                                                      }
                                                                    },
                                                                    "v-icon",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " help "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Opção exclusiva para Usuários Lite. Selecione esta opção para definir se o Usuário será apenas de consulta. Isso implica que este usuário não poderá emitir comandos para títulos, podendo apenas visualizá-los. "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            {
                              staticClass: "pa-6",
                              attrs: { cols: "12", sm: "12", md: "6" }
                            },
                            [
                              _vm.inserirSenha
                                ? _c(
                                    VCard,
                                    {
                                      staticClass: "grey lighten-4 text-center",
                                      staticStyle: {
                                        display: "grid",
                                        padding: "24px 24px 8px 24px"
                                      },
                                      attrs: { "min-height": "100%" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "padding-bottom": "16px",
                                            "justify-content": "space-between",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          _c(
                                            VCardText,
                                            {
                                              staticClass: "text-left",
                                              class: !_vm.isExtraSmall
                                                ? "pa-3"
                                                : ""
                                            },
                                            [
                                              _vm._v(
                                                " Informe abaixo a nova senha para esse usuário: "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-center align-center"
                                            },
                                            [
                                              _c(
                                                VTooltip,
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              VBtn,
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "mr-3 white--text",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      "x-small":
                                                                        "",
                                                                      fab: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.gerarSenhaAutomatica()
                                                                      }
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    "mdi-key"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    34602139
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      " Gerar senha automaticamente para o novo usuário "
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                VBtn,
                                                {
                                                  attrs: {
                                                    "x-small": "",
                                                    fab: "",
                                                    disabled:
                                                      _vm.validarDisableCriarSenha
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.inserirSenha = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(VIcon, [
                                                    _vm._v("mdi-close")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(VTextField, {
                                        attrs: {
                                          solo: "",
                                          "prepend-icon": !_vm.isExtraSmall
                                            ? "lock"
                                            : "",
                                          autocomplete: "new-password",
                                          value: _vm.userPassword,
                                          label: "Crie uma senha",
                                          hint:
                                            "Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)",
                                          "append-icon": _vm.mostrarSenha
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.mostrarSenha
                                            ? "text"
                                            : "password",
                                          rules: _vm.passwordRules,
                                          "data-cy": "usuarioPwd"
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            _vm.mostrarSenha = !_vm.mostrarSenha
                                          },
                                          input: function($event) {
                                            return _vm.$refs.passwordConfirmation.validate()
                                          }
                                        },
                                        nativeOn: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "tab",
                                                9,
                                                $event.key,
                                                "Tab"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.$refs.passwordConfirmation.focus()
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "progress",
                                              fn: function() {
                                                return [
                                                  _vm.custom
                                                    ? _c(VProgressLinear, {
                                                        attrs: {
                                                          value: _vm.progress,
                                                          absolute: "",
                                                          height: "7"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          false,
                                          3343315850
                                        ),
                                        model: {
                                          value: _vm.usuario.password,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.usuario,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "usuario.password"
                                        }
                                      }),
                                      _c(VTextField, {
                                        ref: "passwordConfirmation",
                                        attrs: {
                                          solo: "",
                                          "prepend-icon": !_vm.isExtraSmall
                                            ? "lock"
                                            : "",
                                          name: "new-passaword",
                                          label: "Repita a nova senha",
                                          type: _vm.mostrarSenha
                                            ? "text"
                                            : "password",
                                          "append-icon": _vm.mostrarSenha
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          rules: _vm.passwordRulesConfirmation,
                                          required: "",
                                          "data-cy": "usuarioPwdConfirm"
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            _vm.mostrarSenha = !_vm.mostrarSenha
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.usuario.passwordConfirmation,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.usuario,
                                              "passwordConfirmation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "usuario.passwordConfirmation"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    VCard,
                                    {
                                      staticClass:
                                        "grey lighten-4 text-center d-flex align-center justify-center",
                                      attrs: { "min-height": "100%" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { display: "grid" } },
                                        [
                                          _c(
                                            VIcon,
                                            {
                                              staticClass: "ma-3",
                                              attrs: {
                                                elevation: "4",
                                                size: "120",
                                                color: "grey lighten-4--text"
                                              }
                                            },
                                            [_vm._v("mdi-lock")]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              staticClass:
                                                "green white--text ma-3 pa-3",
                                              attrs: {
                                                "data-cy": "liberarInserirSenha"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.inserirSenha = true
                                                }
                                              }
                                            },
                                            [
                                              _c(VIcon, [
                                                _vm._v("mdi-lock-reset")
                                              ]),
                                              _vm._v(
                                                _vm._s(
                                                  _vm.isExtraSmall
                                                    ? "Alterar Senha"
                                                    : "Criar Senha do Usuário"
                                                )
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VDialog,
                    {
                      attrs: { "max-width": "60vw", "max-height": "60vh" },
                      model: {
                        value: _vm.modalPermissoes,
                        callback: function($$v) {
                          _vm.modalPermissoes = $$v
                        },
                        expression: "modalPermissoes"
                      }
                    },
                    [
                      _c(
                        VToolbar,
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  _vm.modalPermissoes = false
                                }
                              }
                            },
                            [_c(VIcon, [_vm._v("mdi-close")])],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "12", sm: "12", align: "left" } },
                            [
                              _c(VToolbarTitle, [
                                _vm._v(" Permissões de Usuário ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCard,
                        {
                          staticClass: "py-4 px-8",
                          staticStyle: { "border-radius": "0" }
                        },
                        [
                          _c(
                            VCardText,
                            {
                              staticClass: "mx-0 mt-0 mb-3 pa-0",
                              staticStyle: { "font-weight": "500" }
                            },
                            [
                              _vm._v(
                                " Confira na tabela abaixo os detalhes das permissões para cada usuário: "
                              )
                            ]
                          ),
                          _c(
                            VCard,
                            [
                              _c(VDataTable, {
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.itensPermissoes,
                                  "show-group-by": "",
                                  "footer-props": {
                                    itemsPerPageOptions: [10, 50, 100],
                                    itemsPerPageText: "Itens por página:"
                                  },
                                  "group-by": "category",
                                  height: "60vh"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.admin",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            VChip,
                                            {
                                              attrs: {
                                                color: _vm.getColor(item.admin)
                                              }
                                            },
                                            [
                                              _c(VIcon, [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getBolean(item.admin)
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.basic",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            VChip,
                                            {
                                              attrs: {
                                                color: _vm.getColor(item.basic)
                                              }
                                            },
                                            [
                                              _c(VIcon, [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getBolean(item.basic)
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.lite",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            VChip,
                                            {
                                              attrs: {
                                                color: _vm.getColor(item.lite)
                                              }
                                            },
                                            [
                                              _c(VIcon, [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getBolean(item.lite)
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: {
        "max-width": "80vw",
        persistent: "",
        scrollable: "",
        color: "background",
        transition: "dialog-bottom-transition"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        VCard,
        [
          _c(
            VCardTitle,
            { staticClass: "pa-0" },
            [
              _c(
                VToolbar,
                { attrs: { flat: "", dark: "", dense: "", color: "primary" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { icon: "", dark: "" },
                      on: { click: _vm.fecharModal }
                    },
                    [_c(VIcon, [_vm._v("mdi-close")])],
                    1
                  ),
                  _c(VToolbarTitle, { staticClass: "pa-0" }, [
                    _vm._v(" Histórico do Usuário ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(VDivider),
          _c(
            VCardText,
            { staticClass: "py-5 px-6 text-justify content-style" },
            [
              _c("div", { staticClass: "pb-4" }, [
                _c(
                  "span",
                  { staticClass: "text-h6 font-weight-medium black--text" },
                  [
                    _vm._v(
                      "Consulta de histórico de ações do usuário " +
                        _vm._s(
                          _vm.usuario.name +
                            " (Id: " +
                            _vm.usuario.id_user +
                            ")"
                        ) +
                        " nos últimos 30 dias."
                    )
                  ]
                )
              ]),
              _c(VDataTable, {
                staticClass: "elevation-1 pa-3",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.historicos,
                  "item-key": "historicos",
                  "footer-props": {
                    itemsPerPageOptions: [10, 20, 50, -1],
                    itemsPerPageText: "Históricos por página:"
                  },
                  "data-cy": "tabelaHistoricos"
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.historico_datahora",
                    fn: function(props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.formatarDataHoraMoment(
                                props.item.historico_datahora,
                                "DD/MM/YYYY HH:mm"
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(VDivider),
          _c(
            VCardActions,
            { staticClass: "justify-end" },
            [
              _c(
                VBtn,
                {
                  staticClass: "ml-4",
                  attrs: { "data-cy": "fecharConfirmacaoModal" },
                  on: { click: _vm.fecharModal }
                },
                [_vm._v(" Fechar ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
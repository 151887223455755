import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mostrarConfirmacao
        ? _c("ConfirmacaoModal", {
            attrs: {
              showDialog: _vm.mostrarConfirmacao,
              tituloModal: "Usuário sem senha",
              textoModal:
                "Ao criar um usuário sem definir uma senha, será atribuída a senha padrão <b>Troqueme@1</b> e o novo usuário deverá redefinir sua senha no primeiro acesso. <br /><br /><b>Importante:</b> é preciso que o e-mail informado seja válido para a redefinição da senha. <br /><br />Deseja prosseguir?",
              btnConfirmarEmit: "salvarUsuario"
            },
            on: {
              salvarUsuario: _vm.salvarUsuario,
              fecharModal: function($event) {
                _vm.mostrarConfirmacao = false
              }
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
        [
          _c(
            VCard,
            { staticClass: "mb-3", attrs: { align: "left", justify: "left" } },
            [
              _c(
                VCardText,
                { staticClass: "pa-3 font-weight-light white black--text" },
                [
                  _c(
                    VIcon,
                    { staticClass: "primary--text lighten-2 pa-2" },
                    [_vm._v(" mdi-application-edit-outline ")]
                  ),
                  _vm._v("Usuários > Gerenciar Usuários do sistema ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCard,
            {
              staticClass: "pa-3 mb-4",
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.aplicarFiltros.apply(null, arguments)
                }
              }
            },
            [
              _c(
                VRow,
                { staticClass: "pa-3" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "d-flex flex-row pa-0",
                      attrs: { cols: "6", xs: "6", sm: "6", md: "4", lg: "4" }
                    },
                    [
                      _c(VTextField, {
                        staticClass: "pa-0 ma-0",
                        attrs: {
                          name: "filtro",
                          outlined: "",
                          label: "Filtro",
                          type: "text",
                          "hide-details": "",
                          dense: "",
                          disabled: _vm.loading,
                          "data-cy": "filtroUsuario"
                        },
                        model: {
                          value: _vm.filtroUsuario,
                          callback: function($$v) {
                            _vm.filtroUsuario = $$v
                          },
                          expression: "filtroUsuario"
                        }
                      })
                    ],
                    1
                  ),
                  _c(VSpacer),
                  _c(
                    VCol,
                    {
                      staticClass: "d-flex justify-end align-center pa-0",
                      attrs: { cols: "6", xs: "6", sm: "6", md: "4", lg: "4" }
                    },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            color: "green white--text",
                            "data-cy": "criarUsuario"
                          },
                          on: { click: _vm.criarUsuario }
                        },
                        [
                          _vm._v(" Criar novo usuário "),
                          _c(VIcon, { staticClass: "ml-2" }, [_vm._v("add")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.snackbar
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbar,
                  corSnackbar: _vm.snackbarColor,
                  mensagemSnackbar: _vm.mensagem
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbar = false
                  }
                }
              })
            : _vm._e(),
          _c(VDataTable, {
            staticClass: "elevation-1 pa-3",
            class: _vm.isExtraSmall ? "alinhar-botao-editar" : "",
            attrs: {
              headers: _vm.dynamicHeaders,
              items: _vm.usuarios,
              loading: _vm.loading,
              "loading-text": "Carregando Usuários...",
              "item-key": "usuarios",
              "footer-props": {
                itemsPerPageOptions: [10, 20, 50, -1],
                itemsPerPageText: "Usuários por página:"
              },
              "data-cy": "tabelaUsuarios",
              search: _vm.filtroUsuario
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.st_ativo",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(VSwitch, {
                        style: {
                          color: item.st_ativo != "" ? "green" : "red"
                        },
                        attrs: {
                          color: item.st_ativo != "" ? "green" : "red",
                          readonly: "",
                          inset: ""
                        },
                        model: {
                          value: item.st_ativo,
                          callback: function($$v) {
                            _vm.$set(item, "st_ativo", $$v)
                          },
                          expression: "item.st_ativo"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "item.tp_usuario",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.is_lite_readonly
                              ? item.tp_usuario + " (apenas leitura)"
                              : item.tp_usuario
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.historico",
                  fn: function(props) {
                    return [
                      _c(
                        VBtn,
                        {
                          staticClass: "secondary white--text pa-0",
                          attrs: {
                            fab: "",
                            dark: "",
                            small: "",
                            loading: _vm.dialogLoading,
                            "data-cy": "mostrarHistorico" + props.index
                          },
                          on: {
                            click: function($event) {
                              return _vm.exibirHistorico(props.item)
                            }
                          }
                        },
                        [
                          _c(VIcon, { attrs: { dark: "" } }, [
                            _vm._v(" mdi-account-details ")
                          ])
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.tp_perfil",
                  fn: function(props) {
                    return [
                      props.item.tp_perfil === "API"
                        ? _c(
                            VBtn,
                            {
                              staticClass: "secondary white--text",
                              attrs: { icon: "" }
                            },
                            [
                              _c(VIcon, { attrs: { dark: "" } }, [
                                _vm._v(" mdi-api ")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function(props) {
                    return [
                      _c(
                        VBtn,
                        {
                          staticClass: "grey white--text",
                          class: !_vm.isExtraSmall ? "mr-2" : "mb-2 mr-3",
                          attrs: {
                            elevation: "0",
                            width: "90%",
                            loading: _vm.dialogLoading,
                            "data-cy": "editarUsuario" + props.index
                          },
                          on: {
                            click: function($event) {
                              return _vm.exibirDetalhe(props.item)
                            }
                          }
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "mr-2",
                              style: { color: "white" },
                              attrs: { medium: "" },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalhe(props.item)
                                }
                              }
                            },
                            [_vm._v("edit")]
                          ),
                          _vm._v("Editar ")
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm.mostrarHistorico
        ? _c("HistoricoUsuarioModal", {
            attrs: {
              showDialog: _vm.mostrarHistorico,
              historicos: _vm.usuarioHistoricos,
              usuario: _vm.usuarioHistorico
            },
            on: {
              fecharModal: function($event) {
                _vm.mostrarHistorico = false
              }
            }
          })
        : _vm._e(),
      _vm.dialog
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "90%",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _vm.snackbar
                ? _c("Snackbar", {
                    attrs: {
                      mostrarSnackbar: _vm.snackbar,
                      corSnackbar: _vm.snackbarColor,
                      mensagemSnackbar: _vm.mensagem
                    },
                    on: {
                      fecharSnackbar: function($event) {
                        _vm.snackbar = false
                      }
                    }
                  })
                : _vm._e(),
              _c(
                VCard,
                { staticClass: "ml-1" },
                [
                  _c(
                    VCardTitle,
                    { staticClass: "pa-0" },
                    [
                      _c(
                        VToolbar,
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialog }
                            },
                            [_c(VIcon, [_vm._v("mdi-close")])],
                            1
                          ),
                          _c(VToolbarTitle, [_vm._v("Criar Usuário")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardText,
                    { staticClass: "pa-4" },
                    [
                      _c("CriarUsuario", {
                        attrs: {
                          usuario: _vm.usuario,
                          validarForm: _vm.validarForm,
                          mostrarConfirmacao: _vm.mostrarConfirmacao
                        },
                        on: {
                          setIsInvalid: function($event) {
                            _vm.isInvalid = $event
                          },
                          formValidado: function($event) {
                            _vm.validarForm = $event
                          },
                          salvarUsuario: _vm.salvarUsuario,
                          fecharModal: _vm.closeDialog
                        }
                      })
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardActions,
                    {
                      style: !_vm.isExtraSmall
                        ? "display: flex; justify-content: end"
                        : "display: flex; justify-content: center"
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-5",
                          attrs: {
                            width: _vm.isExtraSmall ? "100" : "120",
                            color: "green white--text",
                            loading: _vm.loadingButtonModal,
                            "data-cy": "salvarUsuario"
                          },
                          on: { click: _vm.verificaUsuario }
                        },
                        [_vm._v(" Salvar ")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-5",
                          attrs: {
                            dense: "",
                            color: "error",
                            width: _vm.isExtraSmall ? "100" : "120"
                          },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v(" Cancelar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dialogEditar
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": _vm.superUsuario ? "1200px" : "600px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogEditar,
                callback: function($$v) {
                  _vm.dialogEditar = $$v
                },
                expression: "dialogEditar"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    { staticClass: "pa-0" },
                    [
                      _c(
                        VToolbar,
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialogEditar }
                            },
                            [_c(VIcon, [_vm._v("mdi-close")])],
                            1
                          ),
                          _c(VToolbarTitle, [_vm._v("Editar Usuário")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardText,
                    { staticClass: "pa-4" },
                    [
                      _c("EditarUsuario", {
                        attrs: {
                          usuario: _vm.usuario,
                          validarForm: _vm.validarForm
                        },
                        on: {
                          setIsInvalid: function($event) {
                            _vm.isInvalid = $event
                          },
                          formValidado: function($event) {
                            _vm.validarForm = $event
                          },
                          salvarUsuario: _vm.salvarUsuario,
                          fecharModal: _vm.closeDialogEditar
                        }
                      })
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardActions,
                    {
                      style: !_vm.isExtraSmall
                        ? "display: flex; justify-content: end"
                        : "display: flex; justify-content: center"
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-5",
                          attrs: {
                            width: _vm.isExtraSmall ? "100" : "120",
                            color: "green white--text",
                            loading: _vm.loadingButtonModal,
                            "data-cy": "salvarUsuarioEditado"
                          },
                          on: { click: _vm.verificaUsuario }
                        },
                        [_vm._v(" Salvar ")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-5",
                          attrs: {
                            width: _vm.isExtraSmall ? "100" : "120",
                            dense: "",
                            color: "error"
                          },
                          on: { click: _vm.closeDialogUsuario }
                        },
                        [_vm._v(" Cancelar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }